// ** React Imports
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

// ** Axios
import axios from 'axios'

// ** Google Login Import
import { useGoogleLogin } from '@react-oauth/google'

// ** Hooks
import { useAuth } from 'hooks/useAuth'

// ** MUI Components
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import Alert from '@mui/material/Alert'

// ** Custome Component
import FallbackSpinner from 'core/components/spinner'
import { CircularProgress, TextField } from '@mui/material'

const LoginV1 = () => {
  // ** Hooks
  const { login, loading } = useAuth()

  // ** State
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)

  // ** Functions
  const handleLogin = () => {
    console.log('---hi----')
    login({ email, password }, err => {
      conosle.log('---err---', err)
    })
  }

  return (
    <Box
      className='content-center'
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 10,
          width: '400px',
          py: 10,
          px: 10,
          border: '1px solid #BDBDBD',
          borderRadius: '12px'
        }}
      >
        <Typography fontSize={18} fontWeight={600}>
          Please sign in with your account.
        </Typography>
        <TextField
          value={email}
          onChange={e => setEmail(e.target.value)}
          label='Email'
          placeholder='john.doe@gmail.com.'
          type='text'
          fullWidth
        />
        <TextField
          value={password}
          onChange={e => setPassword(e.target.value)}
          label='Password'
          placeholder='******'
          type='password'
          fullWidth
        />
        <Button
          onClick={handleLogin}
          sx={{ fontSize: 14, fontWeight: 600, width: '100%', boxShadow: 0, bgcolor: "#008000" }}
          variant='contained'
          size='medium'
          startIcon={loading && <CircularProgress size="14px" sx={{ color: 'white' }} />}
        >
          Login
        </Button>
      </Box>
    </Box>
  )
}

export default LoginV1
