// React Imports
import React, { useState } from 'react'

// MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

// ** Redux Imports
import { editTemplate, templatesSelector } from 'store/templates'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'

const SaveTemplateDialog = props => {
  // ** Props
  const { id, data } = props

  // ** Hooks
  const dispatch = useDispatch()

  // ** Selectors
  const { template, loading } = useSelector(templatesSelector)

  // ** State
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  // ** Functions
  const handleClickOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const onSuccess = () => {
    setSuccess(true)
    setTimeout(() => {
      setSuccess(false)
      setOpen(false)
      setName('')
    }, 1500)
  }

  const saveHandler = () => {
    const body = {
      name,
      savedElements: data,
      status: 'inactive'
    }
    if (template?.id) dispatch(editTemplate(template?.id, body, onSuccess))
  }

  console.log('---te----', template)

  return (
    <>
      <Button
        sx={{
          '&:disabled': { backgroundColor: 'gray' },
          '&:hover': { backgroundColor: '#0065ff' },
          backgroundColor: '#0052cc',
          borderRadius: '2px',
          height: '24px',
          width: 'fit-content',
          whiteSpace: 'nowrap',
          boxShadow: 0
        }}
        disabled={!data && !template?.id}
        onClick={handleClickOpen}
        variant='contained'
        size='small'
        color='primary'
      >
        Save as template
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Save this diagram as template</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <TextField
              label='Template name'
              variant='standard'
              size='small'
              value={name}
              fullWidth
              required
              onChange={e => setName(e.target.value)}
            />
            {success ? (
              <Box mt={5} bgcolor='success.light' px={5} py={2} borderRadius='8px'>
                <Typography fontSize={14} fontWeight={600} color='success.main'>
                  Your template is saved successfully! you can active it from "Templates" section.
                </Typography>
              </Box>
            ) : (
              <Typography variant='body2' fontSize={12} mt={5}>
                By pressing save button, this template will be saved in templates sections and you can you use later for
                furthur purposes.*
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='secondary'
            size='medium'
            sx={{
              borderRadius: '2px'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={saveHandler}
            variant='contained'
            size='medium'
            disabled={!name}
            startIcon={loading ? <CircularProgress size={16} sx={{ color: 'white' }} /> : null}
            sx={{
              '&:disabled': { backgroundColor: 'gray' },
              '&:hover': { backgroundColor: '#0065ff' },
              backgroundColor: '#0052cc',
              borderRadius: '2px'
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SaveTemplateDialog
