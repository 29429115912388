import { del, get, post, put, patch, uploadFile } from './api_setup'
import * as url from './url_helper'

// ** Authentification
const postSignin = data => post(url.POST_SIGNIN_ADMIN, data)
const getProfile = (configs = {}) => get(url.GET_ADMIN_PROFILE, configs)

// ** Groups
const getGroupTypes = () => get(url.GET_GROUP_TYPES)
const getGroups = status => get(url.GET_GROUPS + '?status=' + status)
const getGroup = id => get(url.GET_GROUP + id)
const addGroups = data => post(url.POST_GROUPS, data)
const editGroups = (id, data) => put(url.PUT_GROUPS + id, data)

// ** Groups
const getItems = status => get(url.GET_ITEMS + '?status=' + status)
const getPredefinedItems = status => get(url.GET_PREDEFINED_ITEMS + '?status=active')
const getItem = id => get(url.GET_ITEM + id)
const addItem = data => post(url.POST_ITEM, data)
const editItem = (id, data) => put(url.PUT_ITEM + id, data)

// ** Templates
const getTemplates = status => get(url.GET_TEMPLATES + '?status=' + status)
const getTemplate = id => get(url.GET_TEMPLATE + id)
const addTemplate = data => post(url.POST_TEMPLATE, data)
const editTemplate = (id, data) => put(url.PUT_TEMPLATE + id, data)

// ** Upload
const upload = file => uploadFile(url.POST_UPLOAD_FILE, file)

export {
  getGroups,
  addGroups,
  editGroups,
  getGroup,
  getItems,
  getItem,
  addItem,
  editItem,
  upload,
  getGroupTypes,
  getTemplates,
  getTemplate,
  addTemplate,
  editTemplate,
  postSignin,
  getProfile,
  getPredefinedItems
}
