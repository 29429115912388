// ** React Imports
import React, { useState, Fragment } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'

// ** Icons Imports
import BellOutline from 'mdi-material-ui/BellOutline'

// ** Third Party Components
import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Custom Components Imports
import CustomChip from '../../../components/mui/chip'
import CustomAvatar from '../../../components/mui/avatar'

// ** Icons imports

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    borderRadius: '0.5em',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const styles = {
  maxHeight: 344,
  '& .MuiMenuItem-root:last-of-type': {
    border: 0
  }
}

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  ...styles
})

const NotificationDropdown = props => {
  // ** Variables
  // const data = [{
  //   title: "Congratulation Flora! 🎉",
  //   description: "Won the monthly best seller badge",
  //   date: "Today"
  // },
  // {
  //   title: "New user registered.",
  //   description: "5 hours ago",
  //   date: "Yesterday"
  // }
  // ]
  const data = []

  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)

  // ** Hook
  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const ScrollWrapper = ({ children }) => {
    if (hidden) {
      return <Box sx={{ ...styles, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>
    } else {
      return (
        <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>
      )
    }
  }

  return (
    <Fragment>
      <IconButton
        aria-haspopup='true'
        onClick={handleDropdownOpen}
        aria-controls='customized-menu'
        sx={{ color: 'common.white' }}
      >
        <BellOutline sx={{ color: 'text.secondary' }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <ScrollWrapper>
          {data.length < 1 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: '2rem' }}>
              <Typography sx={{ fontSize: '0.825rem', color: 'text.secondary', fontWeight: 600 }}>
                You're all caught up !
              </Typography>
            </Box>
          )}
        </ScrollWrapper>
        <MenuItem
          disableRipple
          sx={{
            py: 2,
            borderBottom: 0,
            borderTop: theme => `1px solid ${theme.palette.grey[300]}`
          }}
        >
          <Button variant='contained' onClick={handleDropdownClose} sx={{ borderRadius: '0.3rem', marginLeft: 'auto' }}>
            View All
          </Button>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default NotificationDropdown
