import { createSlice } from '@reduxjs/toolkit'
import { getGroups, addGroups, editGroups, getGroup, getGroupTypes } from 'configs/axios/api_helper'

// ** API Imports

export const initialState = {
  loading: null,
  groupsData: [],
  groupTypesData: [],
  group: {},
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const groupsSlice = createSlice({
  name: 'groupsData',
  initialState,
  reducers: {
    addGroup: (state, action) => {
      state.groupsData.push(action.payload)
      state.success = 'CREATE_GROUP'
      state.loading = null
    },
    updateGroup: (state, action) => {
      state.groupsData = state.groupsData.map(group =>
        group.id.toString() === action.payload.id.toString() ? { group, ...action.payload } : group
      )
      state.success = 'UPDATE'
      state.loading = null
    },
    removeGroup: (state, action) => {
      state.groupsData = state.groupsData.filter(group => group.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getGroupsDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.groupsData = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET'
    },
    getGroupTypesDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.groupTypesData = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET_GROUP_TYPES'
    },
    getGroupSuccess: (state, action) => {
      state.group = action.payload
      state.loading = null
      state.success = 'GET_GROUP'
    },
    getGroupsDataFailure: state => {
      state.loading = null
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.group = {}
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addGroup,
  updateGroup,
  getGroupSuccess,
  startLoading,
  resetMessages,
  getGroupTypesDataSuccess,
  getGroupsDataFailure,
  getGroupsDataSuccess
} = groupsSlice.actions

// export user selector to get the slice in any component
export const groupsSelector = state => state.groupsData

// export The reducer
const groupsReducer = groupsSlice.reducer
export default groupsReducer

// Actions
export const fetchGroups =
  (status = 'active,inactive') =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_GROUPS'))
      const { data } = await getGroups(status)
      dispatch(getGroupsDataSuccess(data ?? []))
    } catch (error) {
      dispatch(getGroupsDataFailure())
    }
  }

export const fetchGroupTypes = () => async dispatch => {
  try {
    dispatch(startLoading('GET_GROUP_TYPES'))
    const { data } = await getGroupTypes()
    dispatch(getGroupTypesDataSuccess(data ?? []))
  } catch (error) {
    dispatch(getGroupsDataFailure())
  }
}

export const fetchGroup = id => async dispatch => {
  try {
    dispatch(startLoading('GET_GROUP'))
    const { data } = await getGroup(id)
    dispatch(getGroupSuccess(data ?? {}))
  } catch (error) {
    dispatch(getGroupsDataFailure())
  }
}

export const createGroup = (payload, navigate) => async dispatch => {
  try {
    dispatch(startLoading('CREATE_GROUP'))
    const data = await addGroups({ ...payload, type: 'group' })
    dispatch(addGroup(data))
    navigate('/groups')
  } catch (error) {
    dispatch(getGroupsDataFailure())
  }
}

// export const deleteDueDate = id => async dispatch => {
//   try {
//     dispatch(startLoading('DELETE'))
//     const data = await editMetadata(id, { status: 'archived' })
//     dispatch(removeDueDate(id))
//   } catch (error) {
//     dispatch(getGroupsDataFailure())
//   }
// }

export const editGroup = (id, group, navigate) => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_GROUP'))
    const data = await editGroups(id, { ...group })
    navigate('/groups')
  } catch (error) {
    console.log('---errr---', error)
    dispatch(getGroupsDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
