import { createSlice } from '@reduxjs/toolkit'
import {
  getTemplate,
  getTemplates,
  addTemplate as addTemplateApi,
  editTemplate as editTemplateApi
} from 'configs/axios/api_helper'

// ** API Imports

export const initialState = {
  loading: null,
  templatesData: [],
  template: {},
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const templatesSlice = createSlice({
  name: 'templatesData',
  initialState,
  reducers: {
    addTemplate: (state, action) => {
      state.templatesData.push(action.payload)
      state.template = action.payload
      state.success = 'CREATE_TEMPLATE'
      state.loading = null
    },
    updateTemplate: (state, action) => {
      state.template = { ...state.template, ...action.payload }
      state.success = 'UPDATE_TEMPLATE'
      state.loading = null
    },
    removeTemplate: (state, action) => {
      state.templatesData = state.templatesData.filter(template => template.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getTemplatesDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.templatesData = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET'
    },
    getTemplateSuccess: (state, action) => {
      state.template = action.payload
      state.loading = null
      state.success = 'GET_TEMPLATE'
    },
    getTemplatesDataFailure: state => {
      state.loading = null
      //handling Errors
      state.error = 'GET_TEMPLATES'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.template = {}
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addTemplate,
  updateTemplate,
  removeTemplate,
  getTemplateSuccess,
  startLoading,
  resetMessages,
  getTemplatesDataSuccess,
  getTemplatesDataFailure
} = templatesSlice.actions

// export user selector to get the slice in any component
export const templatesSelector = state => state.templatesData

// export The reducer
const templatesReducer = templatesSlice.reducer
export default templatesReducer

// Actions
export const fetchTemplates =
  (status = 'active,inactive') =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_TEMPLATES'))
      const { data } = await getTemplates(status)
      dispatch(getTemplatesDataSuccess(data ?? []))
    } catch (error) {
      dispatch(getTemplatesDataFailure())
    }
  }

export const fetchTemplate = id => async dispatch => {
  try {
    dispatch(startLoading('GET_TEMPLATE'))
    const { data } = await getTemplate(id)
    dispatch(getTemplateSuccess(data ?? {}))
  } catch (error) {
    dispatch(getTemplatesDataFailure())
  }
}

export const createTemplate = payload => async dispatch => {
  try {
    dispatch(startLoading('CREATE_TEMPLATE'))
    const { data } = await addTemplateApi({ ...payload })
    dispatch(addTemplate(data))
  } catch (error) {
    dispatch(getTemplatesDataFailure())
  }
}

// export const deleteDueDate = id => async dispatch => {
//   try {
//     dispatch(startLoading('DELETE'))
//     const data = await editMetadata(id, { status: 'archived' })
//     dispatch(removeDueDate(id))
//   } catch (error) {
//     dispatch(getTemplatesDataFailure())
//   }
// }

export const editTemplate = (id, template, callBack) => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_TEMPLATE'))
    const data = await editTemplateApi(id, { ...template })
    dispatch(updateTemplate(template))
    callBack && callBack()
  } catch (error) {
    dispatch(getTemplatesDataFailure())
  }
}

export const editTemplates = templates => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_TEMPLATES'))
    const data = await Promise.all(
      templates.map(template => {
        const { id, ...rest } = template
        editTemplateApi(id, { ...rest, status: 'active' })
      })
    )
  } catch (error) {
    dispatch(getTemplatesDataFailure())
  }
}

export const cleanMessages = () => async dispatch => {
  console.log('---execute---')
  dispatch(resetMessages())
}
