// ** React imports
import React from 'react'

// ** MUI Imports
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useMediaQuery } from '@mui/material'

// ** Components
import UserDropdown from 'core/layouts/components/shared-components/UserDropdown'
import NotificationDropdown from 'core/layouts/components/shared-components/NotificationDropdown'
import ApplicationsDropdown from 'core/layouts/components/shared-components/ApplicationsDropdown'

// ** Icons Imports
import TooltipIcon from 'mdi-material-ui/HelpCircleOutline'

// ** Auth Hook
import { useAuth } from 'hooks/useAuth'

const AppBarContent = props => {
  // ** Props
  const { hidden, settings, saveSettings } = props

  //** Hooks
  const auth = useAuth()
  const medium = useMediaQuery(theme => theme.breakpoints.down('md'))

  return (
    <Box sx={{ width: '25%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(!medium ? { mx: '2rem' } : { mx: 1 })
        }}
      ></Box>
      <UserDropdown settings={settings} />
    </Box>
  )
}

export default AppBarContent
