// ** React Imports
import React, { useState, Fragment } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu from '@mui/material/Menu'

// ** Icons Imports
import DotsGrid from 'mdi-material-ui/DotsGrid'

// ** Images imports

// ** Third Party Components
// import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Custom Components Imports

// ** Context
import { useAuth } from 'hooks/useAuth'

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
    '& .MuiMenu-paper': {
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        width: 380,
        overflow: 'hidden',
        borderRadius: "0.5em",
        marginTop: theme.spacing(2.5),
        marginLeft: theme.spacing(15),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    '& .MuiMenu-list': {
        //padding: 0
    }
}))






const ApplicationsDropdown = props => {


    // ** Props
    const { settings } = props

    // ** States
    const [anchorEl, setAnchorEl] = useState(null)

    // ** Hooks
    const { user } = useAuth()
    const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))

    // ** Vars
    const { direction } = settings

    const handleDropdownOpen = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = () => {
        setAnchorEl(null)
    }

 



    return (
        <Fragment>
            <IconButton aria-haspopup='true' onClick={handleDropdownOpen} aria-controls='customized-menu' sx={{ color: 'common.white' }}>
                <DotsGrid sx={{ color: 'text.secondary' }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
            >
                <Grid container sx={{ my: 0, mx: 0 }}>
                 
                </Grid>

            </Menu>
        </Fragment>
    )
}

export default ApplicationsDropdown
