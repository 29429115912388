// ** React Imports
import React, { useState, Fragment, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

// ** Custom Components
import AddNewGroup from '../components/AddNewGroup'

// ** Icons Imports
import EditIcon from 'mdi-material-ui/PencilOutline'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

// ** 3rd Part Libraries
import moment from 'moment'
import { useDebouncedCallback } from 'use-debounce'
import { fetchGroups, groupsSelector } from 'store/groups'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`
}))

const Container = styled('div')(({ theme }) => ({
  padding: '10px 20px 10px 20px'
}))

const Row = props => {
  // ** Hooks
  const navigate = useNavigate()
  // ** Props
  const { row, key } = props

  // ** Redirect to customer display
  const redirectHandler = id => {
    navigate('/groups/edit-group/' + id)
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell sx={{ maxWidth: 150 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, color: 'common.blue', cursor: 'pointer' }}>
            <Typography
              sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '0.875rem' }}
            >
              {row.name}
            </Typography>
          </Box>
        </TableCell>
        <TableCell align='left'>{row.description || '------'}</TableCell>
        <TableCell align='left'>{row.status || '------'}</TableCell>
        <TableCell align='left'>{row.createdAt ? moment(row.createdAt).format('YYYY-MM-DD') : '------'}</TableCell>
        <TableCell align='left'>{row.updatedAt ? moment(row.updatedAt).format('YYYY-MM-DD') : '------'}</TableCell>
        <TableCell align='center'>
          <IconButton size='small' onClick={() => redirectHandler(row.id)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const GroupsList = () => {
  // ** Hooks
  const dispatch = useDispatch()

  // ** State
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  // ** State
  const [paginationFilters, setPaginationFilters] = useState({
    limit: 20,
    page: 1,
    field: ''
  })
  // ** Selectors
  const { groupsData, pagination, success, error, loading } = useSelector(groupsSelector)

  // ** Fetch Tax data
  useEffect(() => {
    dispatch(fetchGroups())
  }, [])

  // ** Functions
  const handleChangePage = (event, newPage) => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPaginationFilters({
      ...paginationFilters,
      page: ++newPage
    })
  }

  const handleChangeRowsPerPage = event => {
    setPaginationFilters({
      ...paginationFilters,
      limit: event.target.value
    })
  }

  const debounced = useDebouncedCallback(
    event => {
      const { value } = event.target
      setPaginationFilters({
        ...paginationFilters,
        field: value
      })
    },
    400,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'end', py: 4 }}>
        <AddNewGroup />
      </Box>

      <StyledCard
        sx={{
          position: 'relative',
          '& .customer-column-header': {
            backgroundColor: 'common.white',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600
            }
          }
        }}
      >
        <CardHeader />
        <Box sx={{ position: 'relative' }}>
          <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align='left'>
                    Description
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align='left'>
                    Status
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align='left'>
                    Created At
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align='left'>
                    Updated At
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} align='center'>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupsData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map(row => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
              {groupsData?.length < 1 ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: 'center' }}>
                      <Typography>No Data Found.</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50]}
            component='div'
            count={pagination.total}
            rowsPerPage={pagination.limit}
            page={pagination.currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Backdrop
            open={loading == 'GET_GROUPS'}
            sx={{
              position: 'absolute',
              color: theme => theme.palette.common.blue,
              zIndex: theme => theme.zIndex.mobileStepper - 1,
              '&.MuiBackdrop-root': {
                backgroundColor: 'common.white'
              }
            }}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </Box>
      </StyledCard>
    </Container>
  )
}

export default GroupsList
