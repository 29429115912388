// ** Signin
export const POST_SIGNIN_ADMIN = '/auth/admins/signin'
export const GET_ADMIN_PROFILE = '/auth/admins/me'

// ** Groups
export const GET_GROUP_TYPES = '/metaData/type/groupType'
export const GET_GROUPS = '/metaData/type/group'
export const GET_GROUP = '/metaData/'
export const POST_GROUPS = '/metaData/'
export const PUT_GROUPS = '/metaData/'

// ** Items
export const GET_ITEMS = '/metaData/type/item'
export const GET_ITEM = '/metaData/'
export const POST_ITEM = '/metaData/'
export const PUT_ITEM = '/metaData/'
export const GET_PREDEFINED_ITEMS = '/metaData/type/predefined'

// ** Templates
export const GET_TEMPLATES = '/templates'
export const POST_TEMPLATE = '/templates/'
export const PUT_TEMPLATE = '/templates/'
export const GET_TEMPLATE = '/templates/'

// ** UPload
export const POST_UPLOAD_FILE = '/uploads'
