import { createSlice } from '@reduxjs/toolkit'
import {
  getItem,
  getItems,
  addItem as addItemApi,
  editItem as editItemApi,
  getPredefinedItems
} from 'configs/axios/api_helper'

// ** API Imports

export const initialState = {
  loading: null,
  itemsData: [],
  item: {},
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const itemsSlice = createSlice({
  name: 'itemsData',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.itemsData.push(action.payload)
      state.success = 'CREATE_ITEM'
      state.loading = null
    },
    updateItem: (state, action) => {
      state.success = 'UPDATE_ITEM'
      state.loading = null
    },
    removeItem: (state, action) => {
      state.itemsData = state.itemsData.filter(item => item.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getItemsDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.itemsData = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET'
    },
    getItemSuccess: (state, action) => {
      state.item = action.payload
      state.loading = null
      state.success = 'GET_ITEM'
    },
    getItemsDataFailure: state => {
      state.loading = null
      //handling Errors
      state.error = 'GET_ITEMS'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.item = {}
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addItem,
  updateItem,
  removeItem,
  getItemSuccess,
  startLoading,
  resetMessages,
  getItemsDataSuccess,
  getItemsDataFailure
} = itemsSlice.actions

// export user selector to get the slice in any component
export const itemsSelector = state => state.itemsData

// export The reducer
const itemsReducer = itemsSlice.reducer
export default itemsReducer

// Actions
export const fetchItems =
  (status = 'active,inactive') =>
  async dispatch => {
    try {
      dispatch(startLoading('GET_ITEMS'))
      const { data } = await getItems(status)
      dispatch(getItemsDataSuccess(data ?? []))
    } catch (error) {
      dispatch(getItemsDataFailure())
    }
  }

export const fetchPredefinedItems = () => async dispatch => {
  try {
    dispatch(startLoading('GET_PREDEFINED_ITEMS'))
    const { data } = await getPredefinedItems()
    dispatch(getItemsDataSuccess(data ?? []))
  } catch (error) {
    dispatch(getItemsDataFailure())
  }
}

export const fetchItem = id => async dispatch => {
  try {
    dispatch(startLoading('GET_ITEM'))
    const { data } = await getItem(id)
    dispatch(getItemSuccess(data ?? {}))
  } catch (error) {
    dispatch(getItemsDataFailure())
  }
}

export const createItem = (payload, navigate) => async dispatch => {
  try {
    dispatch(startLoading('CREATE_ITEM'))
    const data = await addItemApi({ ...payload, type: 'item' })
    dispatch(addItem(data))
    navigate('/items')
  } catch (error) {
    dispatch(getItemsDataFailure())
  }
}

// export const deleteDueDate = id => async dispatch => {
//   try {
//     dispatch(startLoading('DELETE'))
//     const data = await editMetadata(id, { status: 'archived' })
//     dispatch(removeDueDate(id))
//   } catch (error) {
//     dispatch(getItemsDataFailure())
//   }
// }

export const editItem = (id, item, navigate) => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_ITEM'))
    const data = await editItemApi(id, { ...item })
    dispatch(updateItem(data))
    navigate('/items')
  } catch (error) {
    console.log('--err--', error)
    dispatch(getItemsDataFailure())
  }
}

export const editItems = items => async dispatch => {
  try {
    dispatch(startLoading('UPDATE_ITEMS'))
    const data = await Promise.all(
      items.map(item => {
        const { id, ...rest } = item
        editItemApi(id, { ...rest, status: 'active' })
      })
    )
  } catch (error) {
    dispatch(getItemsDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
