// ** Icon imports
import LicencesIcon from 'mdi-material-ui/GoogleMyBusiness'

// ** Third Party Libraries

import { useAuth } from 'hooks/useAuth'

const navigation = () => {
  const auth = useAuth()

  // ** Initialize base constants

  return [
    {
      title: 'Editor',
      path: '/editor'
    },
    {
      title: 'Groups',
      path: '/groups'
    },
    {
      title: 'Items',
      path: '/items'
    },
    {
      title: 'Templates',
      path: '/templates'
    }
  ]
}

export default navigation
