// ** React Imports
import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

// ** Auth Context
import { useAuth } from 'hooks/useAuth'

// ** Third Party Libraries
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

const AuthGuard = ({ children, redirectTo, protectedRoute }) => {
  // ** Hooks
  const auth = useAuth()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  // ** Initialize base constants
  return children
}

export default AuthGuard
