// ** React Imports
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icons Imports

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { groupsSelector, cleanMessages, createGroup, fetchGroupTypes, editGroup, fetchGroups } from 'store/groups'
import { editItems } from 'store/items'

const defaultValues = {
  name: '',
  description: '',
  status: true,
  type: '',
  group: ''
}

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  padding: 10,
  position: 'relative',
  '& .customer-column-header': {
    backgroundColor: 'common.white',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600
    }
  }
}))

const Container = styled('div')(({ theme }) => ({
  padding: '10px 20px 10px 20px'
}))

const GroupsForm = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** Selectors
  const { groupsData, success, error, loading } = useSelector(groupsSelector)

  // ** Clean up after success
  useEffect(() => {
    dispatch(fetchGroups('hidden'))
  }, [])

  useEffect(() => {
    if (success == 'UPDATE_GROUP') {
      reset({ ...defaultValues })
      setTimeout(() => {
        dispatch(cleanMessages())
        hide(false)
      }, 1000)
    }
  }, [success, error])

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue
  } = useForm({ defaultValues })

  const onSubmit = (data, e) => {
    // Create New GROUP
    // if (data.type === 'predefined') {
    //   const group = groupsData.find(item => item.id === data.group)
    //   const body = {
    //     name: group.name,
    //     description: group.description,
    //     status: data.status ? 'active' : 'inactive'
    //   }
    //   dispatch(editGroup(group.id, body, navigate))
    // } else {
    const body = {
      name: data.name,
      description: data.description,
      status: data.status ? 'active' : 'inactive',
      value: data.type === 'predefined' ? 1 : null
    }
    dispatch(createGroup(body, navigate))
    //}
  }

  return (
    <Container>
      <StyledCard>
        <Typography variant='h5' sx={{ mb: 5 }}>
          Group Information:
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Type: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='type'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value ?? ''}
                      placeholder='Type...'
                      onChange={e => {
                        onChange(e)
                        setValue('description', '')
                        setValue('name', '')
                        setValue('status', true)
                      }}
                      error={Boolean(errors.type)}
                      labelId='type-select'
                      aria-describedby='type-select'
                    >
                      <MenuItem value={null} disabled>
                        <em>Please select a group type...</em>
                      </MenuItem>
                      <MenuItem value='predefined'>Predefined Library</MenuItem>
                      <MenuItem value='custom'>Custom Library</MenuItem>
                    </Select>
                  )}
                />
                {errors.type && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-type'>
                    {errors.type.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Type: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='type'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value ?? ''}
                      placeholder='Type...'
                      onChange={e => {
                        onChange(e)
                        setValue('description', '')
                        setValue('name', '')
                        setValue('status', true)
                      }}
                      error={Boolean(errors.type)}
                      labelId='type-select'
                      aria-describedby='type-select'
                    >
                      <MenuItem value={null} disabled>
                        <em>Please select a group type...</em>
                      </MenuItem>
                      <MenuItem value='container'>Container</MenuItem>
                      <MenuItem value='link'>Link</MenuItem>
                      <MenuItem value='object'>Object</MenuItem>
                    </Select>
                  )}
                />
                {errors.type && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-type'>
                    {errors.type.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Group name: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='name'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      placeholder='XYZ'
                      error={Boolean(errors.name)}
                      aria-describedby='validation-basic-name'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.name && <FormHelperText sx={{ color: 'error.main' }}>This field is required</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Description: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='description'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      placeholder='Description...'
                      multiline
                      rows={4}
                      error={Boolean(errors.description)}
                      aria-describedby='validation-basic-description'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.description && (
                  <FormHelperText sx={{ color: 'error.main' }}>This field is required</FormHelperText>
                )}
              </FormControl>
            </Grid>
            {watch('type') ? (
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>status: </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='status'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label={<Typography>{watch('status') ? 'active' : 'inactive'}</Typography>}
                        style={{ width: 'fit-content' }}
                        control={<Switch onChange={onChange} checked={value} error={Boolean(errors.description)} />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            ) : null}

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                color='inherit'
                sx={{ mx: 2, borderRadius: '5px !important' }}
                size='medium'
                type='submit'
                variant='contained'
                onClick={e => {
                  e.preventDefault()
                  hide(false)
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ borderRadius: '5px !important' }}
                startIcon={
                  (loading == 'UPDATE_GROUP' || loading === 'CREATE_GROUP') && (
                    <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                  )
                }
                disabled={loading == 'UPDATE_GROUP' || loading === 'CREATE_GROUP'}
                size='medium'
                type='submit'
                variant='contained'
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </form>
      </StyledCard>
    </Container>
  )
}

export default GroupsForm
