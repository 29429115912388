import axios from 'axios'
import Cookies from 'js-cookie'

//apply base url for axios

const API_URL = process.env.REACT_APP_BASE_URL //prod
const axiosApi = axios.create({
  baseURL: API_URL
})
axiosApi.defaults.headers.Accept = 'application/json'
axiosApi.defaults.headers.post['Content-Type'] = 'application/json'
axiosApi.interceptors.request.use(
  config => {
    const token = Cookies.get('token')
    if (token) {
      config.headers['x-auth-token'] = token
    }

    return config
  },
  err => Promise.reject(err)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, { ...data }, { ...config }).then(response => response)
}

export async function uploadFile(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi.put(url, { ...data }, { ...config }).then(response => response.data)
}

export async function patch(url, data, config = {}) {
  return axiosApi.patch(url, { ...data }, { ...config }).then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config }).then(response => response.data)
}
