// ** React Imports
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import { Box, CircularProgress, Typography, Button } from '@mui/material'

// ** 3rd Party Libraries
import { DrawIoEmbed } from 'react-drawio'
import { saveAs } from 'file-saver' // For downloading the file
import { dump as yamlDump, load as yamlLoad } from 'js-yaml'
import pako from 'pako' // For decompressing the XML

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { fetchGroups, groupsSelector } from 'store/groups'
import { fetchItems, itemsSelector } from 'store/items'
import { createTemplate, fetchTemplates, templatesSelector } from 'store/templates'

// ** APIs

// ** Custom Components
import SaveTemplateDialog from './SaveTemplateDialog'

const Home = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** State
  const [isLoading, setIsLoading] = useState(true)
  const [formattedGroups, setFormattedGroups] = useState([])
  const [iframeKey, setIframeKey] = useState(Date.now()) // Use a key to force iframe reload
  const [diagramData, setDiagramData] = useState(null)
  const [diagramId, setDiagramId] = useState(null)

  // ** Refs
  const drawioRef = useRef(null)

  // ** Constants
  const randomNumber = Math.floor(Math.random() * (200 - 100 + 1)) + 100
  const randomNumberString = randomNumber.toString()

  // ** Selectors
  const { groupsData, loading: groupsLoading } = useSelector(groupsSelector)
  const { itemsData, loading: itemsLoading } = useSelector(itemsSelector)
  const { template, templatesData, loading: templatesLoading } = useSelector(templatesSelector)

  // ** Fetch Groups
  useEffect(() => {
    dispatch(fetchGroups('active'))
    dispatch(fetchItems('active'))
    dispatch(fetchTemplates('active'))
  }, [])

  // ** Find the iframe inside DrawIoEmbed once it renders

  // ** Constants
  useEffect(() => {
    const fetchData = async () => {
      const formattedGroups = await Promise.all(
        groupsData
          ?.filter(item => item.status === 'active')
          ?.map(async group => ({
            title: { main: group.name },
            entries: [
              {
                id: group.name?.toLowerCase(),
                title: { main: group.name },
                libs: [
                  {
                    title: { main: group.name },
                    data: await Promise.all(
                      itemsData
                        ?.filter(item => item.parentId === group.id && item.status === 'active')
                        ?.map(async item => {
                          return {
                            xml:
                              item.value === 1 && item.type === 'item'
                                ? item.path
                                : `<mxGraphModel><root><mxCell id="0"/><mxCell id="1" parent="0"/><mxCell id="2" name="${item.name}" style="shape=image;image=${item.imageUrl};" vertex="1" parent="1"><mxGeometry x="0" y="0" width="100" height="100" as="geometry"/></mxCell></root></mxGraphModel>`,
                            w: 100,
                            h: 100,
                            aspect: 'fixed'
                          }
                        })
                    )
                  }
                ]
              }
            ]
          }))
      )
      const formattedTemplates = [
        {
          title: { main: 'Templates' },
          entries: [
            {
              id: 'templates',
              title: { main: 'Templates' },
              libs: [
                {
                  title: { main: 'Templates' },
                  data: await Promise.all(
                    templatesData
                      ?.filter(item => item.status === 'active')
                      ?.map(async item => {
                        return {
                          xml: item.savedElements,
                          w: 200,
                          h: 200,
                          aspect: 'fixed'
                        }
                      })
                  )
                }
              ]
            }
          ]
        }
      ]

      setFormattedGroups([...formattedGroups, ...formattedTemplates]) // Set the formatted data into state
    }

    fetchData() // Call the async function inside useEffect
  }, [groupsData, itemsData])

  const defaultLibraries = [
    ...groupsData?.map(group => group.name?.toLowerCase()),
    templatesData?.length > 0 ? 'templates' : ''
  ]?.join(';')
  const enabledLibraries = [
    ...groupsData?.map(group => group.type?.toLowerCase()),
    templatesData?.length > 0 ? 'templates' : ''
  ]

  // Function to extract and decompress the diagram data
  const decompressDiagramData = xmlString => {
    try {
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlString, 'text/xml')
      const diagramTag = xmlDoc.querySelector('diagram')

      if (diagramTag) {
        const compressedData = diagramTag.textContent
        const decodedData = Uint8Array.from(atob(compressedData), c => c.charCodeAt(0))
        const decompressedData = pako.inflateRaw(decodedData, { to: 'string' })

        return decompressedData
      } else {
        console.error('No <diagram> tag found in the XML.')
      }
    } catch (error) {
      console.error('Error during extraction or decompression:', error)
    }
    return null
  }
  // ** Function to generate dynamic YAML sections from XML (based on 'name' attribute)

  const generateYamlSectionsFromXml = xmlDoc => {
    const sections = {}
    const links = []

    // Get all mxCell elements
    const mxCells = xmlDoc.getElementsByTagName('mxCell')

    // Map all cells (vertex elements with a 'name' attribute)
    Array.from(mxCells).forEach(cell => {
      if (cell.getAttribute('vertex') === '1' && cell.hasAttribute('name')) {
        const name = cell.getAttribute('name')
        const id = cell.getAttribute('id')
        const geometry = cell.getElementsByTagName('mxGeometry')[0]

        // Initialize the section if it doesn't exist
        if (!sections[name]) {
          sections[name] = {}
        }

        sections[name][`item ${Object.keys(sections[name]).length + 1}`] = {
          id,
          name: `${name} ${Object.keys(sections[name]).length + 1}`, // Rename based on section name
          x: geometry.getAttribute('x'),
          y: geometry.getAttribute('y'),
          width: geometry.getAttribute('width'),
          height: geometry.getAttribute('height')
        }
      }
    })

    // Map all links (edge elements)
    Array.from(mxCells).forEach(cell => {
      if (cell.getAttribute('edge') === '1') {
        const sourceId = cell.getAttribute('source')
        const targetId = cell.getAttribute('target')

        // Find the respective source and target names
        let sourceSection, targetSection, sourceName, targetName

        Object.keys(sections).forEach(sectionName => {
          const sourceItem = Object.values(sections[sectionName]).find(s => s.id === sourceId)
          const targetItem = Object.values(sections[sectionName]).find(s => s.id === targetId)
          if (sourceItem) {
            sourceSection = sectionName
            sourceName = sourceItem.name
          }
          if (targetItem) {
            targetSection = sectionName
            targetName = targetItem.name
          }
        })

        if (sourceSection && targetSection) {
          const geometry = cell.getElementsByTagName('mxGeometry')[0]
          const points = geometry.getElementsByTagName('mxPoint')

          // Add arrow properties to the links
          links.push({
            source: sourceName,
            target: targetName,
            style: cell.getAttribute('style'), // Add style of the arrow
            geometry: {
              width: geometry.getAttribute('width') || null,
              relative: geometry.getAttribute('relative') || '0',
              points:
                points.length > 0
                  ? Array.from(points).map(point => ({
                      x: point.getAttribute('x') || null,
                      y: point.getAttribute('y') || null
                    }))
                  : [] // Default to an empty array if no points exist
            }
          })
        }
      }
    })

    // Return the sections and links data separately
    return { sections, linksData: { links } }
  }

  // ** Function to handle the export, decompress, and download multiple YAML files dynamically
  const handleExport = data => {
    if (data && data.xml) {
      const rawXml = data.xml
      console.log('Raw data.xml:', data, rawXml)

      const decompressedDiagram = decompressDiagramData(rawXml)

      if (decompressedDiagram) {
        const decodedXml = decodeURIComponent(decompressedDiagram)
        console.log('Decoded XML:', decodedXml)
        setDiagramData(decodedXml)
        dispatch(createTemplate({ savedElements: decodedXml, status: 'archived' }))
      }
    } else {
      console.error('Export failed: No XML data available.')
    }
  }
  // Function to convert YAML to XML
  const convertYamlToXml = yamlData => {
    const data = yamlLoad(yamlData) // Parse the YAML file

    // Create the base mxGraphModel structure
    const doc = new DOMParser().parseFromString(
      '<mxGraphModel><root><mxCell id="0"/><mxCell id="1" parent="0"/></root></mxGraphModel>',
      'text/xml'
    )
    const root = doc.getElementsByTagName('root')[0]

    // Add segregation elements from YAML
    Object.keys(data.segregation).forEach((key, index) => {
      const seg = data.segregation[key]

      const cell = doc.createElement('mxCell')
      cell.setAttribute('id', seg.id)
      cell.setAttribute('style', 'shape=image;image=https://www.svgrepo.com/show/340108/data-center.svg;')
      cell.setAttribute('vertex', '1')
      cell.setAttribute('parent', '1')
      cell.setAttribute('name', seg.name)

      const geometry = doc.createElement('mxGeometry')
      geometry.setAttribute('x', seg.x)
      geometry.setAttribute('y', seg.y)
      geometry.setAttribute('width', seg.width)
      geometry.setAttribute('height', seg.height)
      geometry.setAttribute('as', 'geometry')

      cell.appendChild(geometry)
      root.appendChild(cell)
    })

    // Add links (edges) from YAML with full properties
    data.links.forEach(link => {
      const sourceSegregation = data.segregation[link.source]
      const targetSegregation = data.segregation[link.target]

      if (sourceSegregation && targetSegregation) {
        const sourceId = sourceSegregation.id
        const targetId = targetSegregation.id

        const edge = doc.createElement('mxCell')
        edge.setAttribute('style', link.style || 'edgeStyle=none;orthogonalLoop=1;jettySize=auto;rounded=0;')
        edge.setAttribute('edge', '1')
        edge.setAttribute('parent', '1')
        edge.setAttribute('source', sourceId)
        edge.setAttribute('target', targetId)

        const geometry = doc.createElement('mxGeometry')
        geometry.setAttribute('relative', link.geometry.relative)
        geometry.setAttribute('as', 'geometry')

        // Add points if available
        if (link.geometry.points && link.geometry.points.length > 0) {
          const arrayElement = doc.createElement('Array')
          arrayElement.setAttribute('as', 'points')

          link.geometry.points.forEach(point => {
            if (point.x !== null && point.y !== null) {
              const pointElement = doc.createElement('mxPoint')
              pointElement.setAttribute('x', point.x)
              pointElement.setAttribute('y', point.y)
              arrayElement.appendChild(pointElement)
            }
          })

          geometry.appendChild(arrayElement)
        }

        edge.appendChild(geometry)
        root.appendChild(edge)
      } else {
        console.warn(`Invalid link: segregation ${link.source} or ${link.target} is missing.`)
      }
    })

    // Serialize the XML to string
    const serializer = new XMLSerializer()
    const xmlString = serializer.serializeToString(doc)
    console.log('Converted XML:', xmlString)

    return xmlString
  }

  // Function to handle the YAML file input and convert it back to XML
  const handleYamlImport = event => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = e => {
      const yamlContent = e.target.result

      // Convert the YAML content to XML
      const xmlData = convertYamlToXml(yamlContent)

      // Now you can use the XML data to load it back into the editor
      if (drawioRef.current) {
        drawioRef.current.load({
          xml: xmlData // Load the XML back into the editor
        })
      }
    }

    reader.readAsText(file)
  }

  const generateFilesHandler = () => {
    if (template?.savedElements) {
      const decompressedDiagram = template.savedElements
      // Decode the decompressed data from URL-encoded format
      const decodedXml = decodeURIComponent(decompressedDiagram)
      console.log('Decoded XML:', decodedXml)

      // Parse the decoded XML
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(decodedXml, 'text/xml')

      // Generate YAML sections from the XML (based on the 'name' attribute)
      const { sections, linksData } = generateYamlSectionsFromXml(xmlDoc)

      // Create and download a YAML file for each section
      Object.keys(sections).forEach(sectionName => {
        const yamlData = yamlDump(sections[sectionName], { lineWidth: -1 })
        const yamlBlob = new Blob([yamlData], { type: 'text/yaml' })
        saveAs(yamlBlob, `${sectionName}.yaml`) // Filename based on section name
      })

      // Create and download the links YAML file
      const linksYaml = yamlDump(linksData, { lineWidth: -1 })
      const linksBlob = new Blob([linksYaml], { type: 'text/yaml' })
      saveAs(linksBlob, 'links.yaml')

      // Optionally, you can also still download the original XML if needed
      const xmlBlob = new Blob([decodedXml], { type: 'text/xml' })
      saveAs(xmlBlob, 'diagram.xml')
    }
  }

  // Trigger export action
  const exportHandler = () => {
    if (drawioRef.current) {
      drawioRef.current.exportDiagram({
        format: 'xml' // Trigger export
      })
    }
  }

  return (
    <Box sx={{ height: theme => `calc(100vh - ${theme.mixins.toolbar.minHeight}px) !important` }}>
      {isLoading || groupsLoading || itemsLoading ? (
        <Box height='100%' display='flex' flexDirection='column' alignItems='center' justifyContent='center' gap={2}>
          <CircularProgress sx={{ color: 'primary.main', fontSize: 30 }} />
          <Typography fontSize={14} fontWeight={600}>
            Loading editor in progress...
          </Typography>
        </Box>
      ) : null}
      {(!groupsLoading || !itemsLoading) && formattedGroups?.length > 0 ? (
        <Box height='100%' display={isLoading ? 'none' : 'block'}>
          <Box
            sx={{
              zIndex: 99,
              position: 'absolute',
              right: 60,
              top: 100,
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Button
              sx={{
                '&:disabled': { backgroundColor: 'gray' },
                '&:hover': { backgroundColor: '#0065ff' },
                backgroundColor: '#0052cc',
                borderRadius: '2px',
                height: '24px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                boxShadow: 0
              }}
              disabled={!template?.id}
              variant='contained'
              size='small'
              color='primary'
              onClick={generateFilesHandler}
            >
              Generate
            </Button>

            <SaveTemplateDialog id={diagramId} data={diagramData} />
            <Button
              sx={{
                '&:hover': { backgroundColor: '#0065ff' },
                backgroundColor: '#0052cc',
                borderRadius: '2px',
                height: '24px',
                width: 'fit-content',
                whiteSpace: 'nowrap',
                boxShadow: 0
              }}
              variant='contained'
              size='small'
              color='primary'
              onClick={exportHandler}
            >
              Save
            </Button>
            {/* <Input type='file' accept='.yaml, .yml' onChange={handleYamlImport} /> */}
          </Box>

          <DrawIoEmbed
            key={iframeKey}
            ref={drawioRef}
            configuration={{
              version: randomNumberString,
              simpleLabels: true,
              //pageFormat: { width: '100%', height: '100%' },
              expandLibraries: false,
              defaultLibraries: `${defaultLibraries}`,
              enabledLibraries: [...enabledLibraries],
              libraries: formattedGroups,
              css: '.geMenubarContainer { height: 0 !important;display: none !important } .geMenubar { height: 0 !important;display: none !important;  position: relative !important } .geSearchSidebar { height: 0 !important;display: none !important; } .geSidebarFooter { height: 0 !important;display: none !important; }'
            }}
            urlParameters={{
              lang: 'en',
              libraries: true,
              noExitBtn: true,
              saveAndExit: false,
              keepmodified: false
            }}
            onLoad={data => {
              if (data.pageVisible) setIsLoading(false)
            }}
            onExport={handleExport}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default Home
