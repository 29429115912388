// ** React Imports
import React, { createContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Api Calls
import { getProfile, postSignin } from 'configs/axios/api_helper'
import GoogleSpinner from 'core/components/google-spinner'
import { Box } from '@mui/material'

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  error: false,
  isInitialized: false,
  login: () => null,
  logout: () => null,
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve()
}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(defaultProvider.error)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)
  const [pageLoading, setPageLoading] = useState(false)

  // ** Hooks
  const navigate = useNavigate()

  // ** Get Token from external app redirection
  const search = window.location.search

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true)
      setLoading(true)
      setPageLoading(true)
      try {
        const storedToken = localStorage.getItem('token')
        if (!!storedToken) {
          const profileResponse = await getProfile({ headers: { token: `${storedToken}` } })
          setUser(profileResponse?.data)
          setLoading(false)
          setPageLoading(false)
          navigate('/editor')
        } else {
          localStorage.removeItem('userData')
          localStorage.removeItem('token')
          setUser(null)
          setLoading(false)
          setPageLoading(false)
          navigate('/login')
        }
      } catch (error) {
        console.log('---errr---', error)
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        setUser(null)
        setLoading(false)
        setPageLoading(false)
        navigate('/login')
      }
    }
    initAuth()
  }, [])

  const handleLogin = async (payload, errorCallback) => {
    setLoading(true)
    setError(false)
    try {
      const signinResponse = await postSignin(payload)
      const profileResponse = await getProfile({ headers: { token: `${signinResponse?.data?.token}` } })
      setUser(profileResponse?.data)
      localStorage.setItem('token', signinResponse?.data?.token)
      localStorage.setItem('refreshToken', signinResponse?.data?.refreshToken)
      setLoading(false)
      navigate('/editor')
    } catch (error) {
      console.log('---errr---', error)
      setLoading(false)
      setError(true)
    }
  }

  const handleLogout = () => {
    localStorage.removeItem('userData')
    localStorage.removeItem('token')
    setUser(null)
    setLoading(false)
    navigate('/login')
  }

  const values = {
    user,
    loading,
    error,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout
  }
  if (pageLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          mt: '20%'
        }}
      >
        <GoogleSpinner />
      </Box>
    )
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
