// ** React Imports
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icons Imports
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { itemsSelector, cleanMessages, fetchItem, editItem } from 'store/items'
import { fetchGroups, groupsSelector } from 'store/groups'

// ** APIs
import { upload } from 'configs/axios/api_helper'

const defaultValues = {
  name: '',
  description: '',
  parentId: null,
  imageUrl: '',
  status: 'active',
  value: null
}

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  padding: 10,
  position: 'relative',
  '& .customer-column-header': {
    backgroundColor: 'common.white',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600
    }
  }
}))

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
})

const Container = styled('div')(({ theme }) => ({
  padding: '10px 20px 10px 20px'
}))

const EditGroup = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  // ** State
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState('')

  // ** Selectors
  const { item, success, error, loading } = useSelector(itemsSelector)
  const { groupsData } = useSelector(groupsSelector)

  // ** Clean up after success
  useEffect(() => {
    if (id) {
      dispatch(fetchItem(id))
      dispatch(fetchGroups('active'))
    }
    return () => cleanMessages()
  }, [id])

  useEffect(() => {
    if (item.id) {
      reset({
        name: item.name,
        status: item.status === 'active',
        description: item.name,
        parentId: item.parentId,
        imageUrl: item.imageUrl,
        value: item.value
      })
    }
    return () => reset(defaultValues)
  }, [item])

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset
  } = useForm({ defaultValues })

  // ** Functions
  const handelImage = event => {
    const file = event.target.files?.[0]
    if (file) {
      setIsLoading(true)
      let formData = new FormData()
      formData.set('file', file)

      // Replace with your actual upload function
      upload(formData)
        .then(({ path }) => {
          setIsLoading(false)
          setFileName(file.name) // Set the uploaded file name
          setValue('imageUrl', path)
          // Optionally set the path to the form data using react-hook-form
        })
        .catch(e => {
          setIsLoading(false)
          console.error('Upload failed:', e)
        })
    }
  }

  const onSubmit = (data, e) => {
    // Create New GROUP
    dispatch(editItem(id, { ...data, status: data.status ? 'active' : 'inactive' }, navigate))
  }

  return (
    <Container>
      <StyledCard>
        <Typography variant='h5' sx={{ mb: 5 }}>
          Item Information:
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Group: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='parentId'
                  control={control}
                  //rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value ?? ''}
                      placeholder='Group...'
                      onChange={onChange}
                      error={Boolean(errors.parentId)}
                      disabled={!!watch('value')}
                      labelId='group-select'
                      aria-describedby='group-select'
                    >
                      <MenuItem value={null} disabled>
                        <em>None</em>
                      </MenuItem>
                      {groupsData.map(group => (
                        <MenuItem key={group.id} value={group.id}>
                          {group.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                {errors.parentId && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-parentId'>
                    {errors.parentId.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Item name: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='name'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      placeholder='XYZ'
                      error={Boolean(errors.name)}
                      disabled={!!item.value}
                      aria-describedby='validation-basic-name'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.name && <FormHelperText sx={{ color: 'error.main' }}>This field is required</FormHelperText>}
              </FormControl>
            </Grid>

            {!item.value ? (
              <Grid item xs={12} sm={6}>
                <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Icon: </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='image'
                    control={control}
                    // rules={{
                    //   required: 'This field is required'
                    // }}
                    render={({ field: { onChange } }) => (
                      <Button
                        component='label'
                        role={undefined}
                        variant='outlined'
                        startIcon={!loading && <CloudUploadIcon />} // Show icon only when not loading
                        sx={{ mb: 2 }}
                      >
                        {/* Conditionally show loader, file name, or default text */}
                        {loading ? (
                          <CircularProgress size={24} sx={{ color: 'white' }} />
                        ) : fileName ? (
                          fileName
                        ) : (
                          'Upload SVG File'
                        )}
                        <VisuallyHiddenInput
                          type='file'
                          accept='.svg, .png' // Accept only SVG files
                          onChange={event => {
                            onChange(event) // Update form state
                            handelImage(event) // Handle image upload
                          }}
                        />
                      </Button>
                    )}
                  />
                  {errors.imageUrl && (
                    <FormHelperText sx={{ color: 'error.main' }}>{errors.imageUrl.message}</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Description: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='description'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      placeholder='Description...'
                      multiline
                      rows={4}
                      error={Boolean(errors.description)}
                      aria-describedby='validation-basic-description'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.description && (
                  <FormHelperText sx={{ color: 'error.main' }}>This field is required</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Status: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='status'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      label={<Typography>{watch('status') ? 'active' : 'inactive'}</Typography>}
                      style={{ width: 'fit-content' }}
                      control={<Switch onChange={onChange} checked={value} error={Boolean(errors.description)} />}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                color='inherit'
                sx={{ mx: 2, borderRadius: '5px !important' }}
                size='medium'
                type='submit'
                variant='contained'
                onClick={e => {
                  e.preventDefault()
                  hide(false)
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ borderRadius: '5px !important' }}
                startIcon={loading === 'UPDATE_ITEM' && <CircularProgress sx={{ color: 'common.white' }} size='1rem' />}
                disabled={loading === 'UPDATE_ITEM'}
                size='medium'
                type='submit'
                variant='contained'
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </form>
      </StyledCard>
    </Container>
  )
}

export default EditGroup
