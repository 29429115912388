// ** React Imports
import React from 'react'
import { Navigate, redirect } from 'react-router-dom'

// ** Authentifications
import Login from 'pages/auth'
import Editor from 'pages/editor'

// ** Groups
import Groups from 'pages/groups/containers/GroupsList'
import NewGroup from 'pages/groups/containers/NewGroup'
import EditGroup from 'pages/groups/containers/EditGroup'

// ** Items
import Items from 'pages/items/containers/ItemsList'
import NewItem from 'pages/items/containers/NewItem'
import EditItem from 'pages/items/containers/EditItem'

// ** Templates
import TemplatesList from 'pages/templates/containers/TemplatesList'
import EditTemplate from 'pages/templates/containers/EditTemplate'

const authProtectedRoutes = [
  { path: '/', exact: true, component: () => <>home</> },
  { path: '/editor', component: Editor },
  { path: '/groups', component: Groups },
  { path: '/groups/new-group', component: NewGroup },
  { path: '/groups/edit-group/:id', component: EditGroup },
  { path: '/items', component: Items },
  { path: '/items/new-item', component: NewItem },
  { path: '/items/edit-item/:id', component: EditItem },
  { path: '/templates', component:  TemplatesList},
  { path: '/templates/edit-template/:id', component: EditTemplate },
  { path: '*', component: () => <Navigate to='/' /> }
]

const publicRoutes = [{ path: '/login', component: Login }]

export { authProtectedRoutes, publicRoutes }
