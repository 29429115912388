// ** React Imports
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form'

// ** Icons Imports

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { cleanMessages, editTemplate, fetchTemplate, templatesSelector } from 'store/templates'

const defaultValues = {
  name: '',
  description: '',
  status: 'active'
}

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  padding: 10,
  position: 'relative',
  '& .customer-column-header': {
    backgroundColor: 'common.white',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600
    }
  }
}))

const Container = styled('div')(({ theme }) => ({
  padding: '10px 20px 10px 20px'
}))

const EditTemplate = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  // ** Selectors
  const { template, success, error, loading } = useSelector(templatesSelector)

  // ** Clean up after success
  useEffect(() => {
    if (id) dispatch(fetchTemplate(id))
    return () => cleanMessages()
  }, [id])

  useEffect(() => {
    return () => cleanMessages()
  })

  useEffect(() => {
    if (template.id) {
      reset({ name: template.name, status: template.status === 'active' })
    }
    return () => reset(defaultValues)
  }, [template])

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm({ defaultValues })

  const onSubmit = (data, e) => {
    // Create New GROUP
    dispatch(
      editTemplate(id, { name: data.name, status: data.status ? 'active' : 'inactive' }, () => navigate('/templates'))
    )
  }

  return (
    <Container>
      <Typography variant='h5' sx={{ mb: 5 }}>
        Group Information:
      </Typography>
      <StyledCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>Template name: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='name'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      placeholder='XYZ'
                      error={Boolean(errors.name)}
                      aria-describedby='validation-basic-name'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.name && <FormHelperText sx={{ color: 'error.main' }}>This field is required</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 600, mb: 3, fontSize: 14 }}>status: </Typography>
              <FormControl fullWidth>
                <Controller
                  name='status'
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      label={<Typography>{watch('status') ? 'active' : 'inactive'}</Typography>}
                      style={{ width: 'fit-content' }}
                      control={<Switch onChange={onChange} checked={value} error={Boolean(errors.description)} />}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                color='inherit'
                sx={{ mx: 2, borderRadius: '5px !important' }}
                size='medium'
                type='submit'
                variant='contained'
                onClick={e => {
                  e.preventDefault()
                  hide(false)
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{ borderRadius: '5px !important' }}
                startIcon={
                  (loading == 'UPDATE_GROUP' || loading === 'CREATE_GROUP') && (
                    <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                  )
                }
                disabled={loading == 'UPDATE_GROUP' || loading === 'CREATE_GROUP'}
                size='medium'
                type='submit'
                variant='contained'
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </form>
      </StyledCard>
    </Container>
  )
}

export default EditTemplate
